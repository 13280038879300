<template>
  <main class="mr-4 md:mr-0">
    <trac-loading v-if="loading"></trac-loading>
    <div class="">
      <div
        class="
          flex
          justify-center
          flex-col
          bg-white
          rounded-md
          mt-12
          big-shadow
          p-5
          items-center
        "
      >
        <h1 class="mt-10 text-primaryBlue font-medium">
          Complete KYC by verifying the following.
        </h1>

        <div
          class="grid grid-cols-1 md:grid-cols-3 gap-10 w-full max-w-3xl py-20"
        >
          <div
            @click="
              status.phone === 'VERIFIED'
                ? $router.push({ name: 'ExistingPhoneEmail' })
                : $router.push({ name: 'VerifyEmail' })
            "
            class="
              p-5
              rounded
              h-48
              flex flex-col
              justify-center
              relative
              items-center
              bg-accentLight
              cursor-pointer
            "
          >
            <svg
              v-if="status.phone === 'VERIFIED' && status.email === 'VERIFIED'"
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.2834" cy="10.2834" r="10.2834" fill="#41CC68" />
              <path
                d="M15.0866 7.47949L8.57101 13.9951L5.60938 11.0335"
                stroke="white"
                stroke-width="1.8894"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              class="w-10"
              viewBox="0 0 39 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.65234 17.179V37.8608H14.3469V28.8125V28.8125C14.3469 25.9569 16.5172 23.642 19.1942 23.642C21.8713 23.642 24.0415 25.9569 24.0415 28.8125V28.8125V37.8608H33.7362V17.179"
                stroke="#003283"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.01758 37.8605H37.3723"
                stroke="#003283"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.27266 13.4369H1.27266C0.673885 14.7138 1.15896 16.2668 2.35611 16.9055C2.69295 17.0852 3.06441 17.1787 3.44102 17.1786H34.9485C36.287 17.179 37.3725 16.0219 37.3729 14.5941C37.373 14.1924 37.2853 13.7962 37.1168 13.4369L32.5248 6.83765H5.86467L1.27266 13.4369Z"
                fill="#B4E6FF"
                stroke="#003283"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.86426 6.83769V4.25246L5.86426 4.25246C5.86426 2.82468 6.94936 1.66724 8.28791 1.66724H30.1008V1.66724C31.4393 1.66724 32.5244 2.82468 32.5244 4.25246C32.5244 4.25246 32.5244 4.25246 32.5244 4.25246V6.83769"
                stroke="#003283"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="text-sm mt-10 font-medium text-primaryBlue">
              Verify contact details
            </p>
          </div>
          <!-- <div
          class="p-5 rounded h-48 flex flex-col justify-center items-center  bg-accentLight cursor-pointer"
        >
          <svg
            class="w-8 mt-2 "
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.7057 11.9707V14.1765C15.7073 14.5906 15.5344 14.9862 15.2292 15.2661C14.9241 15.5461 14.5151 15.6844 14.1027 15.6471C11.8401 15.4013 9.66668 14.6281 7.75712 13.3898C5.98053 12.2609 4.47428 10.7546 3.34536 8.97801C2.10269 7.05978 1.32936 4.8758 1.088 2.60301C1.05085 2.19189 1.1882 1.78408 1.46651 1.47921C1.74481 1.17433 2.13844 1.00046 2.55124 1.00007H4.75712C5.49526 0.992807 6.1244 1.53387 6.22771 2.26478C6.32082 2.97071 6.49348 3.66384 6.74242 4.33095C6.94443 4.86836 6.81522 5.47419 6.41153 5.88243L5.47771 6.81625C6.52444 8.65709 8.04863 10.1813 9.88947 11.228L10.8233 10.2942C11.2315 9.8905 11.8374 9.7613 12.3748 9.96331C13.0419 10.2122 13.735 10.3849 14.4409 10.478C15.1803 10.5823 15.7242 11.2242 15.7057 11.9707Z"
              stroke="#253B95"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p class="text-sm mt-10 font-medium text-center text-primaryBlue">
            Verify Phone <br />
            Number
          </p>
        </div> -->
          <!-- @click="$router.push({ name: 'AddBankDetails' })" -->
          <div
            @click="gotoSettlementAccount"
            class="
              p-5
              rounded
              h-48
              flex flex-col
              justify-center
              items-center
              relative
              bg-accentLight
              cursor-pointer
            "
          >
            <svg
              v-if="status.account === 'VERIFIED'"
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.2834" cy="10.2834" r="10.2834" fill="#41CC68" />
              <path
                d="M15.0866 7.47949L8.57101 13.9951L5.60938 11.0335"
                stroke="white"
                stroke-width="1.8894"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              class="w-8 mt-2"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.50065 14.1663H5.00065C4.45348 14.1663 3.91166 14.0586 3.40614 13.8492C2.90061 13.6398 2.44128 13.3329 2.05437 12.946C1.27297 12.1646 0.833984 11.1047 0.833984 9.99967C0.833984 8.89461 1.27297 7.8348 2.05437 7.0534C2.83577 6.27199 3.89558 5.83301 5.00065 5.83301H7.50065M12.5006 5.83301H15.0006C15.5478 5.83301 16.0896 5.94078 16.5952 6.15018C17.1007 6.35957 17.56 6.66649 17.9469 7.0534C18.3338 7.44031 18.6408 7.89964 18.8501 8.40516C19.0595 8.91068 19.1673 9.4525 19.1673 9.99967C19.1673 10.5468 19.0595 11.0887 18.8501 11.5942C18.6408 12.0997 18.3338 12.559 17.9469 12.946C17.56 13.3329 17.1007 13.6398 16.5952 13.8492C16.0896 14.0586 15.5478 14.1663 15.0006 14.1663H12.5006V5.83301Z"
                stroke="#253B95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.66602 10H13.3327"
                stroke="#253B95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p class="text-sm mt-10 font-medium text-center text-primaryBlue">
              Add settlement preference
            </p>
          </div>
          <div
            @click="verifyIdentity"
            class="
              p-5
              rounded
              h-48
              flex flex-col
              relative
              justify-center
              items-center
              bg-accentLight
              cursor-pointer
            "
          >
            <svg
              v-if="status.document === 'VERIFIED'"
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.2834" cy="10.2834" r="10.2834" fill="#41CC68" />
              <path
                d="M15.0866 7.47949L8.57101 13.9951L5.60938 11.0335"
                stroke="white"
                stroke-width="1.8894"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              class="w-8 mt-2"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.91667 12.4993H2.91667C2.68667 12.4993 2.5 12.3127 2.5 12.0827C2.5 11.8527 2.68667 11.666 2.91667 11.666H7.91667C8.14667 11.666 8.33333 11.8527 8.33333 12.0827C8.33333 12.3127 8.14667 12.4993 7.91667 12.4993Z"
                fill="#253B95"
              />
              <path
                d="M7.08333 14.9993H2.91667C2.68667 14.9993 2.5 14.8127 2.5 14.5827C2.5 14.3527 2.68667 14.166 2.91667 14.166H7.08333C7.31333 14.166 7.5 14.3527 7.5 14.5827C7.5 14.8127 7.31333 14.9993 7.08333 14.9993Z"
                fill="#253B95"
              />
              <path
                d="M7.08268 5.83333C6.16352 5.83333 5.41602 5.08583 5.41602 4.16667C5.41602 3.2475 6.16352 2.5 7.08268 2.5C8.00185 2.5 8.74935 3.2475 8.74935 4.16667C8.74935 5.08583 8.00185 5.83333 7.08268 5.83333ZM7.08268 3.33333C6.62352 3.33333 6.24935 3.7075 6.24935 4.16667C6.24935 4.62583 6.62352 5 7.08268 5C7.54185 5 7.91602 4.62583 7.91602 4.16667C7.91602 3.7075 7.54185 3.33333 7.08268 3.33333Z"
                fill="#253B95"
              />
              <path
                d="M10 9.99935C9.77 9.99935 9.58333 9.81268 9.58333 9.58268V8.74935C9.58333 8.06018 9.0225 7.49935 8.33333 7.49935H5.83333C5.14417 7.49935 4.58333 8.06018 4.58333 8.74935V9.58268C4.58333 9.81268 4.39667 9.99935 4.16667 9.99935C3.93667 9.99935 3.75 9.81268 3.75 9.58268V8.74935C3.75 7.60102 4.685 6.66602 5.83333 6.66602H8.33333C9.48166 6.66602 10.4167 7.60102 10.4167 8.74935V9.58268C10.4167 9.81268 10.23 9.99935 10 9.99935Z"
                fill="#253B95"
              />
              <path
                d="M7.91667 17.5H2.08333C0.935 17.5 0 16.565 0 15.4167V2.08333C0 0.935 0.935 0 2.08333 0H12.0833C13.2317 0 14.1667 0.935 14.1667 2.08333V7.13333C14.1667 7.36333 13.98 7.55 13.75 7.55C13.52 7.55 13.3333 7.36333 13.3333 7.13333V2.08333C13.3333 1.39417 12.7725 0.833333 12.0833 0.833333H2.08333C1.39417 0.833333 0.833333 1.39417 0.833333 2.08333V15.4167C0.833333 16.1058 1.39417 16.6667 2.08333 16.6667H7.91667C8.14667 16.6667 8.33333 16.8533 8.33333 17.0833C8.33333 17.3133 8.14667 17.5 7.91667 17.5Z"
                fill="#253B95"
              />
              <path
                d="M14.5827 19.9993C11.596 19.9993 9.16602 17.5693 9.16602 14.5827C9.16602 11.596 11.596 9.16602 14.5827 9.16602C17.5693 9.16602 19.9993 11.596 19.9993 14.5827C19.9993 17.5693 17.5693 19.9993 14.5827 19.9993ZM14.5827 9.99935C12.0552 9.99935 9.99935 12.0552 9.99935 14.5827C9.99935 17.1102 12.0552 19.166 14.5827 19.166C17.1102 19.166 19.166 17.1102 19.166 14.5827C19.166 12.0552 17.1102 9.99935 14.5827 9.99935Z"
                fill="#253B95"
              />
              <path
                d="M13.7496 16.6662C13.6396 16.6662 13.5329 16.622 13.4546 16.5445L11.7879 14.8779C11.6254 14.7154 11.6254 14.4512 11.7879 14.2887C11.9504 14.1262 12.2146 14.1262 12.3771 14.2887L13.7287 15.6404L16.3521 12.642C16.5037 12.4679 16.7671 12.4504 16.9404 12.6029C17.1137 12.7545 17.1312 13.0179 16.9796 13.1912L14.0629 16.5245C13.9871 16.6112 13.8779 16.6629 13.7629 16.6662C13.7587 16.6662 13.7537 16.6662 13.7496 16.6662Z"
                fill="#253B95"
              />
            </svg>

            <p class="text-sm mt-10 font-medium text-center text-primaryBlue">
              verify Identity
            </p>
          </div>
          <trac-modal v-if="pendingModal" @close="pendingModal = false">
            <div class="py-5 px-20">
              <h1 class="mt-8">
                Your Identity status is pending. <br />
                We are currently doing an extensive check on the information
                provided. <br />
                You are advised to check back at a later day. <br />
                Thanks for understanding.
              </h1>
            </div>
          </trac-modal>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      walletDetails: null,
      pendingModal: false,
      status: {
        account: "",
        document: "",
        email: "",
        phone: "",
      },
    };
  },
  async created() {
    this.loading = true;
    let result = await this.$store.dispatch("VERIFY_KYC");
    this.status.account = result.data.account;
    this.status.document = result.data.document;
    this.status.email = result.data.email;
    this.status.phone = result.data.phone;
    this.loading = false;
  },
  methods: {
    verifyIdentity() {
      if (this.status.account === "") {
        this.$router.push({ name: "Photo" });
      } else if (this.status.account === "PENDING") {
        this.pendingModal = true;
      }
    },
    async gotoSettlementAccount() {
      this.loading = true;
      // await this.fetchWalletDetails();

      if (this.status.account === "VERIFIED") {
        this.$router.push({ name: "SettlementPreference" });
      } else {
        this.$router.push({
          name: "AddBankDetails",
          query: {
            // ops: '',
            onboarding: true,
          },
        });
      }
      this.loading = false;
    },
    async fetchWalletDetails() {
      // this.walletDetails = this.$store.getters["GET_WALLET_DETAILS"] || {})[
      //   "data"
      // ];

      await this.$store.dispatch("FETCH_WALLET_DETAILS");
      this.walletDetails = (this.$store.getters["GET_WALLET_DETAILS"] || {})[
        "data"
      ];
      // if (!this.walletDetails) {

      //   console.log(this.walletDetails);
      // }
    },
  },
};
</script>

<style>
.g-gray {
  background: rgba(230, 227, 227, 0.55);
}
</style>
